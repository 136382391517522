function FullName ({fullName}){
    
    
    return (<>
     <section>
        <h1> {fullName} </h1>
    </section>
    
    </>);
}

export default FullName;