const data = {

 /**
     * Contact details
     */
 contact: {
    title: 'Contact',
    list: [
      {
        text: 'jamesfloatingmarket1508@gmail.com',
      },  
      {
        text: '0791 639 7573',
      },
      {
        text: 'The United Kingdom',

      },
    ],
  },

  /**
   * Key skills
   */
  keySkills: {
    title: 'Key skills',
    list: [
      { text: 'Problem solving.'},
      { text: 'Java 17, Spring Boot, JPA, MyBatis.'},
      { text: 'Rest APIs, TDD.' },
      { text: 'Microservices, Event-Driven Development.' },
      { text: 'Linux, Apache Solr' },
      { text: 'Git, CI/CD.' },
    ],
  },

  /**
   * Additional skills
   */
  additionalSkills: {
    title: 'Additional skills',
    list: [
      {text:  '3 years of preventive maintenance, monitoring & deployment.'}, 
      {text:  'CQRS pattern & event sourcing.'},
      { text: 'AWS, Docker, K8S for deployment.'},
      { text: 'React JS, Angular JS.'},
      { text: 'CSS Tailwind, Bootstrap.' },
    ],
  },

  /**
   * Languages I speak
   */
  languages: {
    title: 'Languages',
    list: [
      { text: 'English level conversational.' },
    ],
  },

  /**
   * Hobbies and interests
   */
  interests: {
    title: 'Interests.',
    list: [
      { text: 'Learning new things', icon: '⚛️' },
      { text: 'Building good habits', icon: '📋' },
      { text: 'Football', icon: '⚽' },
    ],
  },

    /**
     * Education, school, etc
     */
    education: {
      title: 'Education',
      list: [
        {
          institution: 'University of Information Technology',
          description: 'Bachelor of Engineering in Information Systems.',
          date: {
            start: '2007-09',
            end: '2012-08',
          },
        },
      ],
    },

}

export default data;