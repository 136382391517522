const data = {
  /**
   * Full name
   */
  fullName: 'James Nhat-Minh Vo',

  /**
   * Summary - short "about me" paragraph
   */
  summary: {
    title: 'Summary',
    text: `
    I'm James, and I'm more than just a Software Engineer. I'm a creator, a problem solver, and a believer in the power of innovation.
At the young age of 20, I embarked on a journey into the world of software engineering.

I've had the privilege of contributing to several remarkable companies as a back-end developer, bringing my passion for code to life in ways I couldn't have imagined. My journey has turned me into a true generalist. I've dived deep into the realms of back-end systems, honing my skills with precision and dedication. I've become fluent in the language of agile software development, embracing change and innovation as guiding principles.

If you're seeking a partner in innovation, a visionary who can turn ideas into reality, I'm here. Together, we can embark on a journey of technology-driven transformation, just as I have throughout my career.`
  },

  projects: {
    title: 'Projects',
    list: [
      {
        name: 'ImageJ',
        techList: [
          'Java',
          'Macro',
          'Git',
        ],
        description: `
        → I was tasked with improving the ImageJ program's speed and automation capabilities. Stem cell scientists use this program once a week to analyze their lab experiment's results. The analysis involves around 100,000 pictures of stem cells and used to take approximately 3 to 4 days of human effort
          
        → I automated the program to take less than 2 hours, and I ended up helping the scientists speed up their science thesis and reports
          `,
      },
      {
        name: 'Cinema booking system.',
        techList: ['Spring', 'MyBatis', 'Oracle', 'Linux'],
        description: `The product is a movie ticket system just like the Cineworld. Please visit at https://www.cgv.id 
       
         → I contributed to the system by releasing new functions into the final product and subsequently monitoring the system and database health. I took charge during system incidents that occurred during our peak times, particularly when blockbuster movies were released. One of my key responsibilities was enhancing the ticket booking API system. Additionally, I redesigned the concurrency pattern for the CGV ticket booking system to address numerous issues that were arising.
       
        →	I successfully minimized booking failures from 100 down to only 2 cases per day. Our scalability efforts involved transitioning from a single Oracle database to Oracle RAC with both an active and standby instance. In a span of just 6 months, we significantly expanded the team from 4 members to 20. Notably, this project contributed approximately USD 20 million in revenue.
       
        →	This high-traffic web application handles over 300 transactions per second and serves more than 20 million users. We constructed the project using a code stack that includes JS, Java 7, Spring 4, Mybatis, and Oracle 11, spanning from front-end to back-end. Our application is built and deployed through Jenkins, operating on the JBOSS EAP 6.4 application server within a RED HAT Linux OS environment.
       
        →	To ensure efficient management, we employed over 30 standalone servers to individually oversee instances for APIs, POS, ERP, and Purchasing systems, totaling more than 30 instances in all. Leveraging the Spring Quartz feature, we automated report calculations around the clock, running particularly during the night shift.
       
        →	Our scalability strategy involved both vertical and horizontal scaling during peak seasons, achieved through a flexible load balancing configuration.
      `
      },
      {
        name: 'Klara',
        techList: ['J2EE','Automation Test','JBDC', 'PostgreSQL', 'Microservices','Bootstrap'],
        description: `Product 1 represents a Human Resources solution that we developed from the ground up. You can learn more about it at https://www.klara.ch/en/homepage
       
        \n →	In this endeavor, our team operated under the Scrum methodology, prioritizing adaptability to changing requirements. I played a pivotal role throughout the software development lifecycle, engaging in performance analysis, design, development, and testing. Our collaborative approach involved pair programming, in-person code reviews, and leveraging tools like SonarQube. At times, we even took on the responsibility of database structure design.
       
        →	The initial launch of the HR segment of Klara took place in March 2017, coinciding with a significant expansion of the Klara team from 8 members to over 30. This milestone marked a major turning point, as the project secured funding exceeding 10 million Swiss Francs.
       
        →	Our technology stack included JS and Bootstrap CSS for front-end development, while J2EE served as the core platform, alongside JSF as our chosen MVC framework for code structuring. Adhering to the principles of Test-Driven Development (TDD), we crafted unit tests using Mockito and integrated tests through Arquillian. For UI testing purposes, we occasionally crafted Selenium tests. Working on Klara was an enriching and enjoyable experience.         
        `
      },
    ],
  },

  /**
   * Work experience
   *
   * Acceptable date format: `YYYY-MM`
   */
  workExperience: {
    title: 'Employment history',
    list: [
      {
        company: ' Freelancer',
        location: 'Nottingham, The UK',
        date: {
          start: '2022-08',
          end: 'Present',
        },
        jobTitle: 'Software Engineer',
      
      },
      {
        company: ' University of Nottingham',
        location: 'Nottingham, The UK',
        date: {
          start: '2022-03',
          end: '2022-08',
        },
        jobTitle: 'Software Engineer',
      
      },
      {
        company: 'CJ Group',
        location: 'Ho Chi Minh City, Vietnam',
        date: {
          start: '2018-03',
          end: '2022-01',
        },
        jobTitle: 'Software Engineer Team Leader',
      

      },
      {
        company: 'Axon Active',
        location: 'Ho Chi Minh City, Vietnam',
        date: {
          start: '2013-11',
          end: '2018-02',
        },
        jobTitle: 'Software Engineer',
     

      },
    ]
  }

};

export default data;