

function WorkExperience({ workExperienceData }) {
  const { title, list } = workExperienceData;

  return (
    <>
    
<div className="box has-background-info-light"> 
  <div className="card">
      <header className="card-header">
        <p className="card-header-title">
          <span>  {title} </span>
        </p> 
      </header>
      <div className="card-content has-background-info-light">
        <div className="content ">
          <ul>
              {list.map(({company, location, date, jobTitle, description})  => 
              <li key={company}>
               <div className="box"> 
                <div className="has-text-weight-semibold"> {company}</div>
                <div className="has-text-weight-semibold">  {jobTitle}</div>    
                {/* <div>{location}  </div> */}
                <p>From {date.start} To {date.end}</p>   
                <p className="is-italic">  {description} </p>
                </div>
              </li>
            )}
        </ul>
        </div>
      </div>
    </div>
</div>

    </>
  );
}

export default WorkExperience;
