
import FullName from "./FullName";
import Summary from "./Summary";
import ResumeData from '../data/ResumeData';

function Banner() {

    return (
        <section class="hero is-info has-background-black">
            <div class="hero-body" style={{ whiteSpace: 'pre-line' }}>
                <p class="title">

                    <FullName fullName={ResumeData.fullName} />
                </p>
                <p class="subtitle">
                    <Summary summary={ResumeData.summary} />
                </p>

                <p><a target="_blank" href="https://github.com/lebronjamesuit">https://github.com/lebronjamesuit</a>
                </p>
            </div>
        </section>

    );
}
export default Banner;